export const optionalRightsConfig = {
  headings: [
    // {
    //   headingLabel: "Item No.",
    //   inputType: "select",
    //   icon: "",
    //   border: true,
    //   width: "7%",
    //   selectOptions: [],
    // },
    {
      headingLabel: "Set Unit",
      inputType: "searchselect",
      icon: "",
      border: true,
      width: "12%",
      selectOptions: [],
    },
    {
      headingLabel: "Detail",
      inputType: "searchselect",
      border: true,
      width: "12%",
      selectOptions: [],
    },
    {
      headingLabel: "Description",
      inputType: "text",
      border: true,
      width: "14%",
    },
    {
      headingLabel: "Qty",
      inputType: "number",
      border: true,
      width: "6%",
      selectOptions: [],
    },
    {
      headingLabel: "Unit Cost",
      inputType: "number",
      border: true,
      width: "7%",
    },
    {
      headingLabel: "Sub Total",
      inputType: "label",
      border: true,
      width: "8%",
    },
    {
      headingLabel: "Adj.",
      inputType: "select",
      border: true,
      width: "8%",
    },
    {
      headingLabel: "Total Cost",
      inputType: "label",
      border: true,
      width: "9%",
    },
    {
      headingLabel: "Revised?",
      inputType: "select",
      border: true,
      width: "7%",
      selectOptions: [],
    },
  ],
  dataNodes: [
    // "gl_number",
    "setunit_id",
    "chartofaccount_id",
    "description",
    "quantity",
    "unit_cost",
    "subtotal_value",
    "currency_exchange",
    "total_cost_value",
    "is_revised",
  ],
  primaryKey: ["fileMaker_po_line_id"],
  nodeFields: [
    {
      column: "info",
    },
  ],
  inlineEdits: [
    // {
    //   dataNode: "gl_number",
    //   width: "7%",
    //   placeholder: "Item No.",
    //   isMandatory: true,
    // },
    {
      dataNode: "setunit_id",
      width: "11%",
      placeholder: "Set Unit",
    },
    {
      dataNode: "chartofaccount_id",
      width: "11%",
      placeholder: "Detail",
      isMandatory: true,
    },
    {
      dataNode: "description",
      width: "13%",
      placeholder: "Description",
    },
    {
      dataNode: "quantity",
      width: "6%",
      validateMethod: "validateNumberFloatOnly",
      placeholder: "Qty",
      isMandatory: true,
    },
    {
      dataNode: "unit_cost",
      width: "6%",
      placeholder: "Unit Cost",
      validateMethod: "validateNumberFloatOnly",
      isMandatory: true,
    },
    {
      dataNode: "subtotal_value",
      width: "8%",
    },
    {
      dataNode: "currency_exchange",
      width: "7%",
      placeholder: "Adj.",
    },
    {
      dataNode: "total_cost_value",
      width: "9%",
    },
    {
      dataNode: "is_revised",
      width: "7%",
    },
    ,
  ],
  showActionsItemBottom:[
  //   {
  //   dataNode: "gl_number",
  //   width: "7%",
  // },
  {
    dataNode: "setunit_id",
    width: "12%",
  },
  {
    dataNode: "chartofaccount_id",
    width: "12%",
  },
  {
    dataNode: "description",
    width: "14%",
  },
  {
    dataNode: "quantity",
    width: "6%",
  },
  {
    dataNode: "unit_cost",
    width: "7%",
  },
  {
    dataNode: "subtotal_value",
    width: "4%",
  },
  {
    dataNode: "currency_exchange",
    width: "12%",
  },
  {
    dataNode: "total_cost_value",
    width: "9%",
  },
  {
    dataNode: "is_revised",
    width: "7%",
  }],
  actions: ["copy", "file", "info-circle", "pen", "times"],
  isClearFields: true,
};
export const optionalRightsList = [
  {
    id: 1,
    right_abbr: "6 YRS HOME VIDEO 06/07",
    fee: "$1500.00",
    term_id: "1 year",
    territory_id: "WW",
    opt_exp_term_id: "1 year",
    option_acquired_date: "MM/DD/YYYY ",
    incl_init: " ",
    CR: " ",
    info: "Test Info 1",
  },
  {
    id: 1,
    right_abbr: "6 YRS HOME VIDEO 06/07",
    fee: "$1500.00",
    term_id: "1 year",
    territory_id: "WW",
    opt_exp_term_id: "1 year",
    option_acquired_date: "MM/DD/YYYY ",
    incl_init: " ",
    CR: " ",
    info: "Test Info 2",
  },
];

export const NewPOHeader = {
  po_id: null,
  po_number: null,
  po_prefix: null,
  vendor_id: null,
  vendor_location_id: null,
  notes: null,
  show_season_id: null,
  is_void: 0,
  prod_company_id: null,
  episode_id: null,
};

export const NewPOLine = [
  {
    po_line_id: null,
    po_id: null,
    episode_id: null,
    //gl_number: null,
    setunit_id: null,
    set_unit: null,
    chartofaccount_id: null,
    acc_detail: null,
    description: null,
    quantity: null,
    unit_cost: null,
    subtotal: null,
    total_cost: null,
    currency_exchange: null,
    created_by: null,
    created_at: null,
    updated_at: null,
    updated_by: null,
    notes: null,
    change_log: [],
  },
];

export const revised = [
  { label: "Y", value: "Y" },
  { label: "N", value: "N" },
];
