import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { withUserContext } from "../../contexts/UserContext";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../components/SharedComponents/SearchSelectField";
import BasicTabs from "../../components/SharedComponents/BasicTabs/BasicTabs";
import TableComponent from "../../components/SharedComponents/Table";
import TablePagination from "@material-ui/core/TablePagination";
import {
  handlePaginationList,
  updateTableSortConfigObject,
  tableSortList,
} from "../../Common/TableHelper";
import PostWatchService from "../../services/service";
import * as Constants from "../../constants/constants";
import { poExportConfig, POExportData } from "./Config";
import "./ExportPO.scss";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class ExportPO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poHeaders: null,
      isFetchingPOHeaders: false,
      isFetchingReport: false,
      orientation: "p",
    };
  }

  componentDidMount() {
    this.getPOHeaders(
      this.props.selectedEpisode?.episode_id,
      this.props?.selectedSeason?.show_season_id
    );
  }

  getPOHeaders = (id, show_season_id) => {
    if (id) {
      this.setState({ isFetchingPOHeaders: true });
      PostWatchService.getData(
        Constants.postWatchServiceBaseUrl +
          "/getpoheaders?episodeId=" +
          id +
          "&showSeasonId=" +
          show_season_id +
          "&sortBy=null&sortOrder=null",
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          this.setState({
            poHeaders: response.data,
            isFetchingPOHeaders: false,
          });
        },
        (err) => {
          this.setState({
            isFetchingPOHeaders: false,
          });
        }
      );
    }
  };

  getPOids = () => {
    let arr = [];
    _.forEach(this.state.poHeaders, (item) => {
      if (item.check) {
        arr.push(item.po_id);
      }
    });
    return arr.join(",");
  };

  onExport = () => {
    let poidStr = this.getPOids();
    let orientation = this.state.orientation;
    if (poidStr?.length > 0) {
      this.setState({ isFetchingReport: true });
      PostWatchService.getDataParams(
        Constants.postWatchServiceBaseUrl +
          "/getexportpodetails?reportName=po_all_report_" +
          orientation +
          "&userId=" +
          this.props?.userContext?.user_profile?.user_id +
          "&poId=" +
          poidStr,
        "",
        this.props.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          this.downloadUrl(response.data, "Show");
          this.setState({
            isFetchingReport: false,
          });
        },
        (err) => {
          this.setState({
            isFetchingReport: false,
          });
        }
      );
    }
  };

  downloadUrl = (url, reportName) => {
    window.open(url, "_blank");
  };

  arrayCheckUncheck = (value, dataItem) => {
    let poHeaders = [...this.state.poHeaders];
    let index = _.findIndex(this.state.poHeaders, { po_id: dataItem.po_id });
    if (index != -1) {
      poHeaders[index].check = value;
    }
    this.setState({ poHeaders });
  };

  selectAll = (value) => {
    let poHeaders = [...this.state.poHeaders];
    _.forEach(poHeaders, (item) => {
      item.check = value;
    });
    this.setState({ poHeaders });
  };

  handleOrientationChange = (value) => {
    this.setState({
      orientation: value,
    });
  };

  render() {
    let isLoading = this.state.isFetchingPOHeaders;
    return (
      <div className="export-po-container">
        {isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <MDBContainer className="ExportPOContainer">
            <MDBRow>
              <MDBCol md={12} className="py-2">
                <Button
                  variant="text"
                  onClick={() => this.selectAll(true)}
                  className="export-po-btn green-button"
                >
                  Select All
                </Button>
                <Button
                  variant="text"
                  onClick={() => this.selectAll(false)}
                  className="export-po-btn red-button"
                >
                  De-Select All
                </Button>
              </MDBCol>
            </MDBRow>
            <MDBRow className="table-striped">
              <MDBCol md={12}>
                <div className="POExportTable">
                  <TableComponent
                    list={this.state?.poHeaders || []}
                    config={poExportConfig}
                    isLoading={this.state.isFetchingPOHeaders || false}
                    maxHeight={"300"}
                    sortCallback={this.sortColumn}
                    arrayCheckUncheck={this.arrayCheckUncheck}
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className="btn-row">
              <MDBCol md={5} className="orientation-radio-btn">
                <FormControl className="radio-btn">
                  <RadioGroup
                    row
                    value={this.state?.orientation}
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    onChange={(e) =>
                      this.handleOrientationChange(e.target.value)
                    }
                  >
                    <MDBCol md={4}>
                      <FormControlLabel
                        value={"p"}
                        control={<Radio />}
                        label="Portrait"
                      />
                    </MDBCol>
                    <MDBCol md={6}>
                      <FormControlLabel
                        value={"l"}
                        control={<Radio />}
                        label="Landscape"
                      />
                    </MDBCol>
                  </RadioGroup>
                </FormControl>
              </MDBCol>
              <MDBCol md={2}>
                {this.state?.isFetchingReport && (
                  <div className="ContentLoader">
                    <CircularProgress />
                  </div>
                )}
              </MDBCol>
              <MDBCol md={2}>
                <BasicButton text={"Export"} onClick={this.onExport} />
              </MDBCol>
              <MDBCol md={2}>
                <BasicButton
                  type="inline"
                  variant="outlined"
                  text={"Cancel"}
                  onClick={this.props.onClose}
                  disabled={false}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )}
      </div>
    );
  }
}

export default withUserContext(ExportPO);
